import dayjs from "dayjs";
import { Book } from "types/book";
import { User } from "types/user";
import AdultBookCover from "assets/imgs/adultBookCover.png";

export const delay = async (ms: number) =>
  new Promise((resolve) => setTimeout(() => resolve(ms), ms));

export const formatNumberWithComma = (x = 0) => {
  if (isNaN(x)) x = 0;
  let returnValue = x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0;
  if (returnValue === "NaN") returnValue = "0";
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0;
};

export const parseNumberWithComma = (x: string) =>
  Number(x.replace(",", "")) ?? 0;

export const makeBookData = (count: number, offset = 0): Book[] => {
  const bookData: Book[] = [];
  for (let i = offset; i < count + offset; i++) {
    bookData.push({
      bookTitle: ``,
      bookCd: `1`,
      author: "",
      TRANSLATOR: "",
      outGb: "A",
      outGbnm: "정상",
      jgQty: 0,
      isbn: "",
      ipgoDate: "",
      pubName: "",
      bookMarkCnt: 0,
      reviewCnt: 0,
      purchaseRate: 100,
      discountRate: 10,
      rsvRate: 5,
      bookPrice: 15000,
      isNewBook: i % 2 == 1,
      openDate: "19700101",
      onelineComment: ``,
      bookAbstract: "",
      adultYn: "N",
      tags: [],
      topic: "",
      bookTitle_ORIGIN: "",
      SERIES_TITLE: "",
      reviewData: [],
      cardNews: [],
      classification: "",
      addSign: [],
      seriesTitle: [],
      bookSize: [],
      kcAuth: [],
      bookPreviewImage:
        "/imgs/mall/1.png; /imgs/mall/2.png; /imgs/mall/3.png; /imgs/mall/4.png; /imgs/mall/5.png; /imgs/mall/6.png; /imgs/mall/7.png; /imgs/mall/8.png",
    });
  }
  return bookData;
};

export const parseHTMLContent = (htmlString: string | null) => {
  if (!htmlString) return null;
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  for (let i = 0; i < doc.getElementsByTagName("script").length; i++)
    doc.getElementsByTagName("script").item(i)?.remove?.();
  for (let i = 0; i < doc.body.getElementsByTagName("script").length; i++)
    doc.body.getElementsByTagName("script").item(i)?.remove?.();

  if (doc.body.innerText == "null") return null;

  return doc.body.innerText;
};

export const getThumbnailUrl = (youtubeId: string | undefined) =>
  `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`;

export const getBookImgUrl = (book: Book, user: User) =>
  user.adultYn == "N" && (book?.adultYn ?? "") == "Y"
    ? AdultBookCover
    : `${process.env.REACT_APP_BUCKET_URL}/data/${book?.coverImg}`;

export const fillBookDummyData = (bookList: Book[] | null | undefined) =>
  bookList
    ? [
        ...bookList.map((book) => ({
          ...book,
          classification: book?.categoryName?.[0] ?? "",
          adultYn: `${book?.adultYn ?? `['']`}`
            .replace("[", "")
            .replace("]", ""),
          content: parseHTMLContent(`${book?.content ?? ""}`),
          review: parseHTMLContent(`${book?.review ?? ""}`),
          biography: parseHTMLContent(`${book?.biography ?? ""}`),
          insideBook: parseHTMLContent(`${book?.insideBook ?? ""}`),
          isNewBook: dayjs().diff(book?.openDate ?? dayjs(), "day") <= 31,
          tags: book?.topic?.split?.(";") ?? [],
          reviewData: [],
          cardNews: [],
          bookPreviewImage: book?.pageImg?.[0]?.replaceAll?.(",", "; ") ?? "",
        })),
      ]
    : [];

export const makeBookPriceAfterDiscount = (book: Book) =>
  Math.ceil((((100 - book.discountRate) / 100) * book.bookPrice) / 10) * 10;
export const makeBookReserveAmount = (book: Book) =>
  Math.floor(((book.rsvRate / 100) * book.bookPrice) / 10) * 10;

export const setMetaTag = ({
  title = "책광장모두",
  description = "책광장 모두에 오신 것을 환영합니다.",
  imageUrl = "logo.png",
}) => {
  //set title
  document
    .querySelector('meta[property="og:title"]')
    ?.setAttribute("content", `${title}`);

  //set description
  document
    .querySelector('meta[property="og:description"]')
    ?.setAttribute("content", description);

  //set images
  document
    .querySelector('meta[property="og:image"]')
    ?.setAttribute("content", imageUrl);

  //set url
  document
    .querySelector('meta[property="og:url"]')
    ?.setAttribute("content", window.location.href);
};
