import { createStore } from "@methodjs/store";
import { User } from "types/user";

export interface UserState {
  isSignedIn: boolean;
  user: User;
}

export const initUserState = (): UserState => ({
  isSignedIn: false,
  user: {
    userId: "",
    userName: "",
    userEmail: "",
    userPhone: "",
    corpName: "",
    corpNo: "",
    authYn: "",
    modAdultDate: "",
    adultYn: "N",
    useYn: "Y",
    userBirthday: "",
    likeBookCount: 0,
    likeShopCount: 0,
    // address: "",
    bookLikeList: [],
    shopLikeList: [],
  },
});

const [useUserStore, setUserStore, getUserStore] = createStore(initUserState);

export { useUserStore, setUserStore, getUserStore };
