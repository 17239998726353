/**
 * Alt + Shift + O로 import 정렬하고 배포하면
 * naver sdk에서 에러 발생.
 * 자연의 신비를 목도하였으니 그대로 내버려둘 것...
 */
import { Badge } from "@mui/material";
import Cart from "assets/imgs/icon/cart.svg";
import Home from "assets/imgs/icon/home.svg";
import SignIn from "assets/imgs/icon/login.svg";
import SignOut from "assets/imgs/icon/logout.svg";
import TopButton from "assets/imgs/icon/mobileTopButton.svg";
import MyPage from "assets/imgs/icon/myPage.svg";
import { useCartAsync } from "hooks/useCartAsync";
import { useMyNavigation } from "hooks/useMyNavigation";
import useUser from "hooks/useUser";
import { useSignOutQuery } from "queries/authQuery";
import React from "react";
import { useLocation } from "react-router-dom";

const MobileStickyFooter = () => {
  const { navigate } = useMyNavigation();
  const { isSignedIn } = useUser();
  const { querySignOut } = useSignOutQuery();
  const location = useLocation();

  // 장바구니 아이템 리스트
  const { cartList } = useCartAsync();

  return (
    <div id="mobile-sticky-footer-wrapper">
      <img
        src={TopButton}
        className="top-button"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
      <div className="mobile-sticky-footer-container">
        <FooterIcon icon={Home} text="홈" onClick={() => navigate("/")} />
        <FooterIcon
          icon={MyPage}
          text="마이페이지"
          onClick={() => navigate("/my-page")}
        />
        <Badge
          color="primary"
          // primary : 파란색, error : 붉은색
          // color="error"
          badgeContent={cartList.length}
          max={9}
        >
          <FooterIcon
            icon={Cart}
            text="장바구니"
            onClick={() => navigate("/cart-view")}
          />
        </Badge>
        {isSignedIn ? (
          <FooterIcon icon={SignOut} text="로그아웃" onClick={querySignOut} />
        ) : (
          <FooterIcon
            icon={SignIn}
            text="모두같이"
            onClick={() => navigate("/modoo-mall")}
          />
        )}
      </div>
    </div>
  );
};

const FooterIcon = ({
  text,
  icon,
  onClick,
}: {
  text: string;
  icon: string;
  onClick: () => void;
}) => {
  return (
    <div className="footer-icon-container" onClick={onClick}>
      <img src={icon} />
      <div>{text}</div>
    </div>
  );
};

export default React.memo(MobileStickyFooter);
