import { useMyNavigation } from "hooks/useMyNavigation";
import useUser from "hooks/useUser";
import { useUserInfoQuery } from "queries/userQuery";
import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface SignedInContainerProps {
  children: ReactNode;
}

const SignedInContainer = ({ children }: SignedInContainerProps) => {
  const { isLoading, isError } = useUserInfoQuery();
  const { isSignedIn } = useUser();
  const { pathname, search } = useLocation();
  const { navigate } = useMyNavigation();
  useEffect(() => {
    if (!isLoading && !isSignedIn && isError) {
      alert("로그인이 필요합니다.");
      navigate(`/sign-in?callback=${pathname}${search}`, {
        replace: true,
      });
    }
  }, [isError, isLoading, isSignedIn, navigate, pathname, search]);

  return <>{children}</>;
};

export default SignedInContainer;
