import React, { ReactNode, Suspense, useCallback } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { ErrorFallbackProps } from "components/ErrorFallback";
import useError from "hooks/useError";

export interface AsyncBoundaryProps {
  errorFallback(props: ErrorFallbackProps): JSX.Element;
  suspenseFallback: ReactNode;
  children: ReactNode;
}

const AsyncBoundary = ({
  suspenseFallback,
  errorFallback,
  children,
}: AsyncBoundaryProps) => {
  const { setError } = useError();

  const resetHandler = useCallback(() => {
    location.reload();
  }, []);

  return (
    <ErrorBoundary
      errorFallback={errorFallback}
      resetHandler={resetHandler}
      setError={setError}
    >
      <Suspense fallback={suspenseFallback}>
        {children} {/* <- fulfilled */}
      </Suspense>
    </ErrorBoundary>
  );
};

export default AsyncBoundary;
