import { axiosInstance } from "config/axiosConfig";

export const deleteReview = async (reviewSeq: string) => {
  const response = await axiosInstance.post("/review/deleteReview", {
    reviewSeq,
  });
  return response.data;
};

export const insertReview = async (reviewData: {
  productSeq: string;
  content: string;
  rated: 1 | 2 | 3 | 4 | 5;
  userId: string;
}) => {
  const response = await axiosInstance.post("/review/insertReview", null, {
    params: reviewData,
  });
  return response.data;
};

export const updateReview = async (reviewData: {
  productSeq: string;
  content: string;
  rated: 1 | 2 | 3 | 4 | 5;
}) => {
  const response = await axiosInstance.post("/review/updateReview", reviewData);
  return response.data;
};
