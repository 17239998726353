import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getBookReviewData } from "api/bookApi";
import { insertReview } from "api/myPageApi";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ResBaseVo } from "types/api";
import { Review } from "types/book";

export const useReviewQuery = (page: number) => {
  const [searchParams] = useSearchParams();
  const bookCd = useMemo(
    () => searchParams.get("bookCd") ?? "",
    [searchParams],
  );
  const query = useQuery<ResBaseVo<{ found: number; reviewList: Review[] }>>(
    ["REVIEW", bookCd, page],
    getBookReviewData(bookCd, page),
    {
      suspense: true,
      enabled: !!bookCd,
    },
  );
  const { data } = query;
  const { reviewData, reviewCount } = useMemo(() => {
    if (!data)
      return {
        reviewData: [],
        reviewCount: 0,
      };
    const { found, reviewList } = data.data;
    return {
      reviewCount: found,
      reviewData: reviewList,
    };
  }, [data]);

  return { ...query, reviewData, reviewCount };
};

export const useInsertReviewMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(insertReview, {
    onSuccess: (res: ResBaseVo<null>) => {
      if (res.resultCd == 200) {
        alert("리뷰가 등록되었습니다.");
        return queryClient.invalidateQueries(["REVIEW"]);
      } else {
        alert("이미 작성하신 리뷰가 존재합니다.");
      }
    },
  });

  return { ...mutation };
};
