import React, { CSSProperties, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import ErrorFallback from "../ErrorFallback";
import SkeletonLoading from "../SkeletonLoading";

interface AsyncContainerProps {
  children: JSX.Element | JSX.Element[];
  suspenseFallback?: JSX.Element;
  style?: CSSProperties;
}

const AsyncContainer = ({
  children,
  style,
  suspenseFallback = <SkeletonLoading style={style} />,
}: AsyncContainerProps) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary, error }) => (
            <ErrorFallback
              resetBoundary={() => resetErrorBoundary()}
              error={error}
              style={style}
            />
          )}
        >
          <Suspense fallback={suspenseFallback}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export default AsyncContainer;
