import type { CookieType } from "types/cookie";

export function getCookie(cookie_name: CookieType) {
  let x, y;
  const val = document.cookie.split(";");
  for (let i = 0; i < val.length; i++) {
    [x, y] = val[i].split("=");
    x = x.replace(/^\s+|\s+$/g, "");
    if (x == cookie_name) {
      return decodeURI(y);
    }
  }
}

export function deleteCookie(cookie_name: CookieType) {
  const exdate = new Date(0);
  const value = "";
  const cookie_value = decodeURI(value) + ";expires=" + exdate.toUTCString();
  document.cookie = cookie_name + "=" + cookie_value;
}
