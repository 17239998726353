import { useEffect, useState } from "react";

const getViewport = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export const useViewport = () => {
  const [viewport, setViewport] = useState(getViewport());
  const [isMobile, setIsMobile] = useState(getViewport().width <= 1400);

  useEffect(() => {
    const handleResize = () => setViewport(getViewport());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsMobile(() => window.innerWidth <= 1400);
  }, [viewport.width <= 1400]);

  return { ...viewport, isMobile };
};
