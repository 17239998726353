import { useMutation } from "@tanstack/react-query";
import { backgroundRefreshToken, signIn, signOut } from "api/authApi";
import useUser from "hooks/useUser";
import { useCallback } from "react";

export const useSignInQuery = () => {
  const mutation = useMutation(signIn, {
    useErrorBoundary: false,
  });

  return {
    ...mutation,
  };
};

export const useSignOutQuery = () => {
  const {
    user: { userId },
  } = useUser();
  const { mutate } = useMutation(signOut, {
    useErrorBoundary: false,
    onSuccess: () => {
      alert("로그아웃되었습니다.");
      location.href = "/";
    },
    onError: (error: {
      code?: string;
      status?: number;
      data: Record<string, unknown>;
    }) => {
      alert(error.data.resultMsg);
      location.href = "/";
    },
  });

  const querySignOut = useCallback(() => {
    if (confirm("로그아웃하시겠습니까?")) {
      mutate({ userId });
    }
  }, [mutate, userId]);
  return { querySignOut };
};

export const useBackgroundRefreshTokenQuery = () => {
  const mutation = useMutation(backgroundRefreshToken);

  return { ...mutation };
};
