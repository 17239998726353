import { usePageClassification } from "hooks/usePageClassification";
import { useState, useEffect, useMemo, useCallback } from "react";
import {
  Content,
  MallContentType,
  MallTemplateType,
  Shop,
  ShopDetail,
  SNSLink,
  UnivMallInfo,
} from "types/shop";
import { useQuery } from "@tanstack/react-query";
import { shopDetailKey } from "constants/queryKey";
import { getShopDetail, getShopList, getTemplateData } from "api/shopApi";
import { ResBaseVo } from "types/api";
import { Book } from "types/book";
import { useBookListWithBookCdQuery } from "./bookQuery";
import { fillBookDummyData } from "utils/commonUtils";
import useUser from "hooks/useUser";
import { useViewport } from "hooks/useViewport";

export const useShopListQuery = () => {
  const [shopListData, setShopListData] = useState<Shop[]>([]);
  const { data, refetch } = useQuery<ResBaseVo<{ shopList: Shop[] }>>(
    ["SHOP"],
    getShopList,
    {
      suspense: true,
    },
  );
  const { user } = useUser();
  const { shopLikeList } = user;

  useEffect(() => {
    if (data) {
      setShopListData([
        ...data.data.shopList.map((shop) => ({
          ...shop,
          hashTag: `${shop?.hashTag ?? ""}`.split("; "),
        })),
      ]);
    }
  }, [data]);

  useEffect(() => {
    const shopList = data?.data.shopList ?? [];
    setShopListData([
      ...shopList
        .filter(
          (shop) =>
            shopLikeList?.find?.((like) => like.refSeq == shop.shopSeq) ??
            false,
        )
        .map((shop) => ({
          ...shop,
          hashTag: `${shop?.hashTag ?? ""}`.split("; "),
        })),
      ...shopList
        .filter(
          (shop) =>
            !shopLikeList?.find?.((like) => like.refSeq == shop.shopSeq),
        )
        .map((shop) => ({
          ...shop,
          hashTag: `${shop?.hashTag ?? ""}`.split("; "),
        })),
    ]);
  }, [data?.data.shopList, shopLikeList]);

  return { shopListData, refetch };
};

export const useShopDetailQuery = () => {
  const { currentShopSeq, currentPageType } = usePageClassification();
  const [shopData, setShopData] = useState<ShopDetail>({
    shopSeq: "",
    bannerTitle: "책광장모두",
    bannerSub: "",
    introContent: "",
    mainBackgroundImage: "",
    bannerImg: "",
    bannerColor: "#ffffff",
    mobileImg: "",
    introImg: "",
    shopTypCd: "O",
    shopTypNm: "입점서점",
    cheersCnt: 0,
    hashTag: [],
    address: "",
    contact: "",
    snsLink: {
      shopSeq: 0,
      etcUrl: "",
      facebookUrl: "",
      instaUrl: "",
      kakaoUrl: "",
      naverUrl: "",
      twitterUrl: "",
      bandUrl: "",
      youtubeUrl: "",
    },
    contents: [],
    shopViewList: [],
    univMallInfo: { shopSeq: "", acdmcYearYn: "N" },
  });

  const { data: queryData } = useQuery<
    ResBaseVo<{
      shopOne: ShopDetail;
      shopSnsList: SNSLink[];
      shopCmptList: Content[];
      shopCmptElementList: Record<string, string>[];
      shopViewList: {
        shopSeq: string;
        tplSeq:
          | "TPL0001"
          | "TPL0002"
          | "TPL0003"
          | "TPL0004"
          | "TPL0005"
          | "TPL0006"
          | "TPL0007"
          | "TPL0009";
        tplName: MallContentType;
        viewYn: "Y" | "N";
      }[];
      univMallInfo: UnivMallInfo;
    }>
  >(shopDetailKey(currentShopSeq), getShopDetail(currentShopSeq), {
    suspense: true,
    useErrorBoundary: false,
    enabled: currentPageType != "MODOO_ONLY" && !!currentShopSeq,
  });
  // 각각의 템플릿에 대한 책 불러오기
  useTemplateBookListQuery(shopData, setShopData, "TPL0001");
  useTemplateBookListQuery(shopData, setShopData, "TPL0002");
  // 새소식은 책이 없어요~
  useTemplateBookListQuery(shopData, setShopData, "TPL0004");
  useTemplateBookListQuery(shopData, setShopData, "TPL0005");
  useTemplateBookListQuery(shopData, setShopData, "TPL0006");
  useTemplateBookListQuery(shopData, setShopData, "TPL0007");
  const {
    count: rcmECount,
    page: rcmEPage,
    rowsPerPage: rcmERowsPerPage,
    handleChangePage: handleRcmEChangePage,
  } = useTemplateBookListQuery(shopData, setShopData, "TPL0009", true);

  useEffect(() => {
    if (queryData) {
      const {
        shopSnsList,
        shopOne,
        shopCmptElementList,
        shopCmptList,
        shopViewList,
        univMallInfo,
      } = queryData.data;
      const contentList: Content[] = shopCmptList.map((template) => {
        const templateElement = shopCmptElementList.find(
          (elem) => elem["SHOP_CMPT_SEQ"] == `${template.shopCmptSeq}`,
        );
        let title = "";
        let content = "";
        const contentText: string[] = [];
        const contentImg: string[] = [];
        let sub = "";
        let date = "";
        let intro = "";
        let keyArray: string[] = [];
        let recommendedBookCd = "";
        let movieUrl = "";
        let coverImg = "";
        let bgColor = "";
        let fontColor = "";
        let templateCd = "A";
        let bookIntro = "";
        const recommendedBook: Book[] = [];
        if (templateElement) {
          keyArray = Object.keys(templateElement);
          keyArray.forEach((key) => {
            const value = templateElement[key];
            if (key.includes("BOOK_SEQ")) {
              if (recommendedBookCd.length == 0) {
                recommendedBookCd += `${value}`;
              } else {
                recommendedBookCd += `;${value}`;
              }
            } else if (key.includes("CONTENT_TEXT")) {
              contentText.push(value);
            } else if (key.includes("CONTENT_IMG")) {
              contentImg.push(value);
            }
          });
          bookIntro = templateElement["BOOK_INTRO"];
          title = templateElement["TITLE"];
          content = templateElement["CONTENT"];
          intro = templateElement["INTRO"];
          sub = templateElement["SUB"];
          date = templateElement["DATE"];
          movieUrl = templateElement["YOUTUBE_URL"];
          bgColor = templateElement["BG_COLOR"];
          fontColor = templateElement["FONT_COLOR"];
          coverImg = templateElement["COVER_IMG"];
          templateCd = templateElement["TEMPLATE_CD"];
        }
        return {
          ...template,
          title,
          content,
          contentText,
          contentImg,
          sub,
          intro,
          bookIntro,
          date,
          recommendedBook,
          recommendedBookCd,
          movieUrl,
          bgColor,
          fontColor,
          coverImg,
          templateCd,
          shopCmptElemList: templateElement,
        };
      });
      setShopData({
        ...shopOne,
        shopViewList,
        hashTag: `${shopOne?.hashTag ?? ""}`.split("; "),
        mainBackgroundImage: shopOne.bannerImg,
        mobileImg: shopOne?.mobileImg ?? shopOne.bannerImg,
        snsLink: shopSnsList[0],
        contents: contentList,
        bannerColor: shopOne?.bannerColor ?? "#ffffff",
        univMallInfo: univMallInfo,
      });
    }
  }, [currentShopSeq, queryData]);

  return {
    shopData,
    rcmECount,
    rcmEPage,
    rcmERowsPerPage,
    handleRcmEChangePage,
  };
};

export const useTemplateBookListQuery = (
  shopData: ShopDetail,
  setShopData: React.Dispatch<React.SetStateAction<ShopDetail>>,
  tplSeq: MallTemplateType,
  pagination = false,
) => {
  const data = shopData.contents.find(
    (content) =>
      content.tplSeq == tplSeq &&
      (tplSeq == "TPL0001" || tplSeq == "TPL0002"
        ? content.repYn == "Y"
        : true),
  );
  const bookCd = data?.recommendedBookCd ?? "";
  const bookCdToArr = bookCd.split(";");
  const viewYn =
    shopData.shopViewList.find((viewList) => viewList.tplSeq == tplSeq)
      ?.viewYn ?? "N";

  const { isMobile } = useViewport();
  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(1);
  }, [isMobile]);
  const [rowsPerPage, count] = useMemo(() => {
    if (isMobile) {
      return [2, Math.ceil(bookCdToArr.length / 2)];
    } else {
      return [12, Math.ceil(bookCdToArr.length / 12)];
    }
  }, [bookCdToArr.length, isMobile]);
  const handleChangePage = useCallback(
    (_: React.ChangeEvent<unknown>, page: number) => {
      setPage(page);
    },
    [],
  );
  const paginatedBookCd = useMemo(
    () =>
      pagination
        ? bookCdToArr
            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
            .join(";")
        : bookCdToArr.join(";"),
    [bookCdToArr, page, pagination, rowsPerPage],
  );
  const { bookList } = useBookListWithBookCdQuery(paginatedBookCd, { viewYn });

  useEffect(() => {
    if (bookList) {
      setShopData((prev) => {
        const contentData = prev.contents.find(
          (content) => content.tplSeq == "TPL0007",
        );
        const commentArr = Object.keys(contentData?.shopCmptElemList ?? {})
          ?.map((key) =>
            key.includes("BOOK_COMMENT")
              ? contentData?.shopCmptElemList?.[key] ?? ""
              : "",
          )
          .filter((v) => v.length > 0);
        const storyIndex = prev.contents.findIndex(
          (content) =>
            content.tplSeq == tplSeq &&
            (tplSeq == "TPL0001" || tplSeq == "TPL0002"
              ? content.repYn == "Y"
              : true),
        );
        if (storyIndex < 0) {
          return prev;
        }
        prev.contents[storyIndex].recommendedBook = fillBookDummyData(
          bookList.map((book, i) => ({
            ...book,
            comment: commentArr?.[i] ?? "",
          })),
        );

        return { ...prev };
      });
    }
  }, [bookList, tplSeq, setShopData]);
  return { count, page, rowsPerPage, handleChangePage };
};

interface ModooCmptType {
  cmptNm: string;
  modooCmptSeq: string;
  tplSeq: string;
  recommendedBookCd: string;
  colorArr?: string[];
  backgroundImage?: string;
  backgroundMobileImage?: string;
}

export const useContentDataQuery = () => {
  /*
  TPL9001 - CMPT0001: 월간모두
  TPL9002 - CMPT0002: 기획전
  TPL9003 - CMPT0003: 테마도서
  TPL9004 - CMPT0004: 책광장 이벤트
  TPL9005 - CMPT0005: 추천도서 (메인페이지)
  TPL9006 - CMPT0006: 이벤트 (메인페이지)
  TPL9007 - CMPT0007: 기획전 (메인페이지)
  TPL0008: 추천도서-메인
  TPL0009: 추천도서-헤더
  TPL0010: 추천도서-배너
  TPL0011: 추천도서-내부
  TPL0012: 모두의 선택
  TPL0013: 일원화 도서
  */
  const { data: queryData } = useQuery<
    ResBaseVo<{
      modooCmptList: ModooCmptType[];
      modooCmptElementList: Record<string, string>[];
    }>
  >(["TEMPLATE"], getTemplateData, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
    cacheTime: Infinity,
  });

  const {
    contentData,
    elementList,
  }: { contentData: ModooCmptType[]; elementList: Record<string, string>[] } =
    useMemo(() => {
      if (!queryData)
        return {
          contentData: [],
          elementList: [],
        };
      const { modooCmptList, modooCmptElementList } = queryData.data;
      return {
        contentData: modooCmptList.map((template) => {
          const templateElement = modooCmptElementList.find(
            (elem) => elem["MODOO_CMPT_SEQ"] == `${template.modooCmptSeq}`,
          );
          let keyArray: string[] = [];
          let recommendedBookCd = "";
          let backgroundImage = "";
          let backgroundMobileImage = "";
          const colorArr: string[] = [];
          if (templateElement) {
            keyArray = Object.keys(templateElement);
            keyArray.forEach((key) => {
              if (key.includes("BOOK_SEQ")) {
                if (recommendedBookCd.length == 0) {
                  recommendedBookCd += `${templateElement[key]}`;
                } else {
                  recommendedBookCd += `;${templateElement[key]}`;
                }
              }
              if (key.includes("COLOR")) {
                colorArr.push(templateElement[key]);
              }
              if (key.includes("BACKGROUND_IMAGE")) {
                backgroundImage = templateElement[key];
              }
              if (key.includes("BACKGROUND_MOBILE_IMAGE")) {
                backgroundMobileImage = templateElement[key];
              }
            });
          }
          if (backgroundMobileImage == "")
            backgroundMobileImage = backgroundImage;
          return {
            ...template,
            recommendedBookCd,
            colorArr,
            backgroundImage,
            backgroundMobileImage,
          };
        }),
        elementList: queryData.data?.modooCmptElementList ?? [],
      };
    }, [queryData]);

  return {
    contentData,
    elementList,
  };
};
