import { createStore } from "@methodjs/store";
import { Menu } from "types/book";

const initMenuState = (): Menu[] => [
  {
    categoryId: "",
    parentCategoryId: "",
    categoryName: "전체",
    categoryFullPath: "",
    categoryDepth: 0,
    categoryOrder: 0,
    childMenu: [],
  },
];

const [useMenuStore, setMenuStore, getMenuStore] =
  createStore<Menu[]>(initMenuState);

export { useMenuStore, setMenuStore, getMenuStore };
