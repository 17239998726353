import axios from "axios";
import { deleteCookie, getCookie } from "utils/cookieUtils";
import { setUserStore } from "stores/userStore";

// let isTokenRefreshing = false;
type HandleTokenRefreshCallback = (accessToken: string) => void;

let refreshSubscribers: Array<HandleTokenRefreshCallback> = [];

const handleTokenRefresh = (accessToken: string) => {
  refreshSubscribers?.map((callback) => {
    callback(accessToken);
  });
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback: HandleTokenRefreshCallback) => {
  refreshSubscribers.push(callback);
};

export const axiosInstance = axios.create({
  timeout: 2 * 60 * 1000, // 2분
  withCredentials: true,
});

axiosInstance.defaults.baseURL = process.env.REACT_APP_BASE_URL ?? "/";

// axiosInstance.interceptors.request.use((req) => {
//   const accessToken = getCookie("md_at");
//   if (accessToken) {
//     isTokenRefreshing = false;
//   }
//   if (req?.headers) {
//     req.headers.Authorization = accessToken ? `Bearer ${accessToken}` : false;
//   }
//   return req;
// });

// axiosInstance.interceptors.response.use(
//   (res) => {
//     if (res?.data?.resultCd) {
//       res.status = res?.data?.resultCd;
//       if (res.status > 400) {
//         return Promise.reject(res);
//       }
//       return res;
//     }
//     return res;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response?.status === 401) {
//       if (
//         originalRequest.headers.Authorization?.length > 10 ||
//         // 헤더에 토큰을 넣었는데 모종의 이유로 401이 재반환된 경우
//         originalRequest.url.includes("/api/user/refreshToken")
//         //refreshToken이 401이 난 경우 (이론상 존재하지 않음)
//       ) {
//         deleteCookie("md_at");
//         axiosInstance.defaults.headers.common["Authorization"] = false;
//       } else {
//         // refreshToken
//         {
//           if (!isTokenRefreshing) {
//             isTokenRefreshing = true;
//             axios
//               .post("api/user/refreshToken?key=scm_store_rt", null, {
//                 baseURL: process.env.REACT_APP_BASE_URL ?? "/",
//                 withCredentials: true,
//               })
//               .then((response) => {
//                 if (response?.data?.data) {
//                   // refresh 성공
//                   console.log("refreshToken success");
//                   console.log(response);

//                   setUserStore((prev) => ({
//                     ...prev,
//                     isSignedIn: true,
//                     user: {
//                       ...prev.user,
//                       modSecretKey: response.data.data.MOD_SECRET_KEY,
//                       userName: response.data.data.NICK_NAME,
//                     },
//                   }));
//                   const newAccessToken = getCookie("md_at");
//                   handleTokenRefresh(newAccessToken as string);
//                   axiosInstance.defaults.headers.common[
//                     "Authorization"
//                   ] = `Bearer ${newAccessToken}`;
//                   return axiosInstance(originalRequest);
//                 } else {
//                   // refresh 실패
//                   // 기존에 쌓여있던 request 초기화
//                   refreshSubscribers = [];
//                   isTokenRefreshing = false;
//                 }
//               })
//               .catch((error) => {
//                 //
//                 console.log("refreshToken failed");
//                 console.log(error);
//                 deleteCookie("md_at");
//                 location.href = `/sign-in?callback=${location.pathname}${location.search}`;
//                 return Promise.reject(error);
//               });
//           } else {
//             const retryOriginalRequest = new Promise((resolve) => {
//               addRefreshSubscriber((accessToken) => {
//                 originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//                 // 이전 리퀘스트 실행.
//                 // resolve는 의미 없음. axios(originalRequest)로 이전 리퀘스트를 실행하고,
//                 // 그 결과를 resolve에 넘기는데, 딱히 실행 결과 갖고 무언가 할 필요가 없음.
//                 resolve(axios(originalRequest));
//               });
//             });
//             return retryOriginalRequest;
//           }
//         }
//       }
//     }
//     return Promise.reject(error);
//   },
// );
