import { useCallback } from "react";
import { initUserState, setUserStore, useUserStore } from "stores/userStore";
import { User } from "types/user";

const useUser = () => {
  const { isSignedIn, user } = useUserStore();

  const setUser = useCallback((newUser: User) => {
    if (newUser)
      setUserStore((prev) => ({
        ...prev,
        isSignedIn: true,
        user: { ...newUser },
      }));
  }, []);

  const initUserStore = useCallback(() => {
    setUserStore(initUserState());
  }, []);

  return {
    isSignedIn,
    user,
    setUser,
    initUserStore,
  };
};

export default useUser;
