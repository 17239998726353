import useUser from "hooks/useUser";
import React, { ReactNode, useEffect } from "react";
import { useMyNavigation } from "hooks/useMyNavigation";
import { getCookie } from "utils/cookieUtils";

interface SignedOutContainerProps {
  children: ReactNode;
}

/* 유저 정보가 있을 때 로그인, 회원가입 등의 페이지 접근을 막는다. */
const SignedOutContainer = ({ children }: SignedOutContainerProps) => {
  const { isSignedIn } = useUser();
  const { navigate } = useMyNavigation();
  useEffect(() => {
    const accessToken = getCookie("md_at");
    if (isSignedIn && accessToken && accessToken.length > 10) {
      navigate("/");
    }
  }, [isSignedIn]);
  return <>{children}</>;
};

export default SignedOutContainer;
