import { useQuery } from "@tanstack/react-query";
import { getServerStatus } from "api/serverApi";
import { useEffect, useState } from "react";

export const useServerStatusQuery = () => {
  const [isMaintaining, setIsMaintaining] = useState(false);
  const { data } = useQuery(["SERVER_STATUS"], getServerStatus, {
    suspense: true,
    refetchInterval: 60 * 1000, // 1분마다 확인
    onError: () => setIsMaintaining(true),
  });
  useEffect(() => {
    data != "ON" && setIsMaintaining(true);
  }, [data]);
  return { isMaintaining };
};
