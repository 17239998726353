import { Button } from "@mui/material";
import React, { CSSProperties, useMemo } from "react";

export interface ErrorFallbackProps {
  error: Error;
  resetBoundary: () => void;
  style?: CSSProperties;
}

const ErrorFallback = ({ error, resetBoundary, style }: ErrorFallbackProps) => {
  const fallbackContainerStyle = useMemo(
    () => ({
      display: "flex",
      flexDirection: "column" as const,
      height: style?.height ?? "auto",
    }),
    [style],
  );
  const errorMessageStyle = useMemo(() => ({ marginTop: "auto" }), []);
  const resetButtonStyle = useMemo(() => ({ margin: "0 auto auto auto" }), []);

  return (
    <div role="alert" style={style}>
      <div style={fallbackContainerStyle}>
        <p style={errorMessageStyle}>오류가 발생했어요!</p>
        <pre>{error.message}</pre>
        <Button
          variant="contained"
          onClick={resetBoundary}
          style={resetButtonStyle}
        >
          재시도하기
        </Button>
      </div>
    </div>
  );
};

export default ErrorFallback;
