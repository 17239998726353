import { useMutation, useQuery } from "@tanstack/react-query";
import { signOut } from "api/authApi";
import {
  getBookmark,
  getKakaoSignInUrl,
  getNaverSignInUrl,
  getUserInfo,
  updateUserInfo,
} from "api/userApi";
import queryKey from "constants/queryKey";
import useUser from "hooks/useUser";
import { setUserStore } from "stores/userStore";
import { ResBaseVo } from "types/api";
import { Bookmark, User } from "types/user";

export const useUserInfoQuery = () => {
  useBookmarkQuery();
  const query = useQuery<User>([queryKey.USER], getUserInfo, {
    onSuccess: (res) => {
      if (res) {
        setUserStore((prev) => ({
          ...prev,
          user: {
            ...prev.user,
            ...res,
          },
          isSignedIn: true,
        }));

        // setUser({ userId, userName, bookLikeList: [], shopLikeList: [] });
      }
    },
    useErrorBoundary: false,
    suspense: true,
    refetchInterval: 1000 * 60 * 45,
    refetchIntervalInBackground: true,
  });
  return { ...query };
};

export const useBookmarkQuery = () => {
  const { isSignedIn, user } = useUser();
  const { userId } = user;

  const { refetch: likeBookRefetch } = useQuery<
    ResBaseVo<{
      bkmrList: Bookmark[];
      found: number;
    }>
  >(["BOOKMARK", "B", userId], getBookmark("B", 2147483647, 0), {
    enabled: isSignedIn,
    onSuccess: (res) => {
      const bookLikeList = res?.data?.bkmrList ?? [];

      setUserStore((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          bookLikeList,
          likeBookCount: res?.data?.found ?? 0,
        },
      }));
    },
  });

  const { refetch: likeShopRefetch } = useQuery<
    ResBaseVo<{
      bkmrList: Bookmark[];
      found: number;
    }>
  >(["BOOKMARK", "S", userId], getBookmark("S", 2147483647, 0), {
    enabled: isSignedIn,
    onSuccess: (res) => {
      const shopLikeList = res?.data?.bkmrList ?? [];
      setUserStore((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          shopLikeList,
          likeShopCount: res?.data?.found ?? 0,
        },
      }));
    },
  });

  return { likeBookRefetch, likeShopRefetch };
};

export const useUpdateUserQuery = () => {
  const mutation = useMutation(updateUserInfo, {
    useErrorBoundary: false,
  });

  return {
    ...mutation,
  };
};

export const useDelUserSignOutQuery = () => {
  const mutation = useMutation(signOut, {
    useErrorBoundary: false,
    onSuccess: () => {
      // alert("로그아웃되었습니다.");
      location.href = "/widthdraw-success";
    },
    onError: (error: {
      code?: string;
      status?: number;
      data: Record<string, unknown>;
    }) => {
      alert(error.data.resultMsg);
      // location.href = "/";
    },
  });

  return {
    ...mutation,
  };
};

export const useSNSSignInUrlQuery = () => {
  const { data: naverUrl } = useQuery<string>(["NAVER"], getNaverSignInUrl, {
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  const { data: kakaoUrl } = useQuery<string>(["KAKAO"], getKakaoSignInUrl, {
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  return { naverUrl, kakaoUrl };
};
