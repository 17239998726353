import { axiosInstance } from "config/axiosConfig";
import { SignInForm, SignUpForm } from "types/user";

export const signIn = async (request: SignInForm) => {
  const response = await axiosInstance.post("/user/signIn", request);
  return response.data;
};

export const signOut = async ({ userId }: { userId: string }) => {
  const response = await axiosInstance.post("/user/signOut", {
    userId,
  });
  return response.data;
};

export const signUp = async (
  request:
    | SignUpForm
    | Pick<SignUpForm, "userId" | "secretKey" | "kakaoId" | "naverId">,
) => {
  let url = "/user/insertUser";
  if (request?.kakaoId) {
    url = "/sns/kakao/signUp";
  }
  if (request?.naverId) {
    url = "/sns/naver/signUp";
  }
  const response = await axiosInstance.post(url, request);
  return response.data;
};

export const backgroundRefreshToken = async () => {
  const response = await axiosInstance.post(
    "/api/user/refreshToken?key=md_rt",
    null,
    {
      withCredentials: true,
    },
  );
  return response.data;
};

export const getPostAuthData = (txId: string) => async () => {
  const response = await axiosInstance.get("/user/getUserAuth", {
    params: {
      txId,
    },
  });
  return response.data;
};
