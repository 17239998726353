import { userIdFindForm, userNewPwForm, userPwFindForm } from "types/user";

const queryKey = {
  USER: "USER",
  SEARCH_RESULT: "SEARCH_RESULT",
  SHOP: "SHOP",
  BOOK: "BOOK",
  TERMS: "TERMS",
  SUPPORT: "SUPPORT",
  ORDER: "ORDER",
  MENU: "MENU",
};
export default queryKey;

export const shopDetailKey = (shopSeq: string) => [queryKey.SHOP, shopSeq];

export const duplicatedCheckKey = (
  target: string,
  item: "ID" | "EMAIL" | "CONTACT",
) => [queryKey.USER, "DUPLICATED", item, target];

export const searchResultKey = (searchParams: URLSearchParams) => [
  queryKey.BOOK,
  queryKey.SEARCH_RESULT,
  searchParams.get("productName"),
  searchParams.get("isbn"),
  searchParams.get("ftIsbn"),
  searchParams.get("categoryId"),
  searchParams.get("search"),
  searchParams.get("author"),
  searchParams.get("pubName"),
  searchParams.get("tag"),
  searchParams.get("isOutOfSaleExcluded"),
  searchParams.get("startDate"),
  searchParams.get("endDate"),
  searchParams.get("startPrice"),
  searchParams.get("endPrice"),
  searchParams.get("filterKeyword"),
  searchParams.get("page"),
  searchParams.get("sort"),
  searchParams.get("filter"),
  searchParams.get("shopSeq"),
];

export const newBookKey = (
  selectedMenu: string,
  startDate?: string,
  endDate?: string,
) => [queryKey.BOOK, "NEW_BOOK", selectedMenu, startDate, endDate];

export const bookDetailKey = (
  bookCd: string,
  sort?: "recent" | "title",
  direction?: "asc" | "desc",
  shopSeq?: string,
) => {
  const key = [queryKey.BOOK, bookCd];
  if (sort) key.push(sort);
  if (direction) key.push(direction);
  if (shopSeq) key.push(shopSeq);
  return key;
};

export const newBookDetailKey = (searchParams: URLSearchParams) => [
  queryKey.BOOK,
  "NEW_BOOK_DETAIL",
  searchParams.get("sort"),
  searchParams.get("page"),
  searchParams.get("mode"),
  searchParams.get("class"),
  searchParams.get("startDate"),
  searchParams.get("endDate"),
];

export const shopListKey = (shopTypCd: string, page: string) => [
  queryKey.SHOP,
  "SHOP_LIST",
  shopTypCd,
  page,
];
export const termsListKey = () => [queryKey.TERMS, "TERMS_LIST"];

export const noticeDetailKey = (ntcSeq: string) => [queryKey.SUPPORT, ntcSeq];

export const oneDetailKey = (otoSeq: string) => [queryKey.SUPPORT, otoSeq];

export const orderSearchKey = (
  userId: string,
  startDate?: string,
  endDate?: string,
  status?: string,
  title?: string,
) => [queryKey.ORDER, startDate, endDate, status, title, userId];

export const rcmMenuListKey = () => [queryKey.BOOK, "RCM_MENU"];

export const rcmBookDetailKey = (searchParams: URLSearchParams) => [
  queryKey.BOOK,
  "RCM_BOOK_DETAIL",
  searchParams.get("catCd"),
  searchParams.get("page"),
  searchParams.get("sort"),
  searchParams.get("year"),
];

export const fieldBookDetailKey = (searchParams: URLSearchParams) => [
  queryKey.BOOK,
  "FIELD_BOOK_DETAIL",
  searchParams.get("sort"),
  searchParams.get("page"),
  searchParams.get("class"),
];

export const userInquiryIdKey = (formData: userIdFindForm) => [
  queryKey.USER,
  "USER_INQUIRY_ID",
  formData.confirmNum,
  formData.userName,
  formData.userEmail,
  formData.userPhone,
];

export const userConfirmNumIdKey = (formData: userIdFindForm) => [
  queryKey.USER,
  "USER_CONFIRM_NUMBER_ID",
  formData.confirmNum,
  formData.userName,
  formData.userEmail,
  formData.userPhone,
];

export const userInquiryPwKey = (formData: userPwFindForm) => [
  queryKey.USER,
  "USER_INQUIRY_PW",
  formData.userId,
  formData.confirmNum,
  formData.userName,
  formData.userEmail,
  formData.userPhone,
];

export const userConfirmNumPwKey = (formData: userPwFindForm) => [
  queryKey.USER,
  "USER_CONFIRM_NUMBER_PW",
  formData.confirmNum,
  formData.userName,
  formData.userEmail,
  formData.userPhone,
];

export const userNewPwKey = (formData: userNewPwForm) => [
  queryKey.USER,
  "USER_NEW_PW",
  formData.certKey,
  formData.userId,
  formData.secretKey,
];

export const SingularBookDetailKey = () => [
  queryKey.BOOK,
  "SINGULA_BOOK_DETAIL",
];

export const newPartnerDetailKey = (searchParams: URLSearchParams) => [
  queryKey.BOOK,
  "PARTNER_BOOK_DETAIL",
  searchParams.get("sort"),
  searchParams.get("page"),
  searchParams.get("mode"),
  searchParams.get("class"),
  searchParams.get("startDate"),
  searchParams.get("endDate"),
  searchParams.get("onelineGb"),
];

export const univBookKey = (searchParams: URLSearchParams) => [
  queryKey.BOOK,
  "UNIV_BOOK",
  searchParams.get("shopSeq"),
  searchParams.get("menuId"),
  searchParams.get("acdmcYear"),
  searchParams.get("smstr"),
  searchParams.get("sort"),
  searchParams.get("page"),
];
