import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useInitWithScrollToTop = () => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    if (!pathname.includes("partner")) window.scrollTo(0, 0);
  }, [pathname, search]);
};

export default useInitWithScrollToTop;
