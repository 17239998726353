import { createStore } from "@methodjs/store";
import { UnivBookMenuListVo } from "types/book";

const initUnivBookMenuState = (): UnivBookMenuListVo[] => [
  {
    id: 0,
    menuNm: "",
    menuLvl: 0,
    sort: 0,
    childMenu: [],
    acdmcYearNum: 0,
    upperId: 0,
  },
];

const [useUnivBookMenuStore, setUnivBookMenuStore, getUnivBookMenuStore] =
  createStore<UnivBookMenuListVo[]>(initUnivBookMenuState);

export { useUnivBookMenuStore, setUnivBookMenuStore, getUnivBookMenuStore };
