import React from "react";
import "./Maintenance.scss";
import MaintenanceImg from "./maintenance.png";

const Maintenance = () => {
  return (
    <div className="maintenance-body">
      <img src={MaintenanceImg} className="imgcenter" />
      <p className="txtblue">
        책광장모두는 현재 <strong className="txtgreen">점검</strong>중이며,
        <br />
        빠른 시간 내에 찾아뵐 수 있도록 노력하겠습니다.
        <br />
        문의사항은 아래 연락처로 부탁드립니다.
        <br />
        <strong className="txtblack">(02-701-0805)</strong>.
      </p>
      <p className="txtwhite">
        방문해 주셔서 <strong>감사</strong>합니다.
      </p>
    </div>
  );
};

export default React.memo(Maintenance);
