import { axiosInstance } from "config/axiosConfig";
import { getUserStore } from "stores/userStore";

export const getCartList = async () => {
  const { user } = getUserStore();
  const { userId } = user;
  const response = await axiosInstance.get("/cart/getListCart", {
    params: {
      userId,
    },
  });
  return response.data;
};

export const insertCart = async (
  cartList: {
    productSeq: string;
    qty: number;
    shopSeq: string;
  }[],
) => {
  const { user } = getUserStore();
  const { userId } = user;
  const response = await axiosInstance.post(
    "/cart/insertCart",
    cartList.map((item) => ({
      ...item,
      userId,
    })),
  );
  return response.data;
};

export const updateCart = async (body: { cartSeq: string; qty: number }) => {
  const response = await axiosInstance.post(
    `/cart/updateCart?cartSeq=${body.cartSeq}&qty=${body.qty}`,
    // , body
  );
  return response.data;
};

export const deleteCart = async (reqList: { cartSeq: string }[]) => {
  const response = await axiosInstance.post("/cart/deleteCart", reqList);
  return response.data;
};
