import React from "react";
import { Modal } from "@mui/material";
import useModal from "hooks/useModal";

const ModalContainer = () => {
  const { open, ModalComponent, modal } = useModal();

  const handleClose = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown",
  ) => {
    reason !== "backdropClick" && modal.close();
    // if (reason === "backdropClick") {
    //   console.log(reason);
    // } else {
    //   modal.close();
    // }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div id="modal-container">{ModalComponent}</div>
    </Modal>
  );
};

export default ModalContainer;
