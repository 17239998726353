import { axiosInstance } from "config/axiosConfig";

export const getEventList = async () => {
  const response = await axiosInstance.get("event/getListEvent");
  return response.data;
};

export const getOneEvent = (eventSeq: string) => async () => {
  const response = await axiosInstance.get("event/getOneEvent", {
    params: {
      eventSeq,
    },
  });
  return response.data;
};
