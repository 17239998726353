const URLs = {
  Main: "/", // 메인
  SignIn: "/sign-in", // 로그인
  SignUp: "/sign-up", // 회원가입
  UserInquiry: "/user-inquiry", // 아이디/비밀번호 찾기
  UserIdInquirySuccess: "/user-inquiry/id", // 아이디 확인
  ResetPassword: "/reset-password", // 비밀번호 재설정
  SearchResult: "/search-result", // 검색 결과
  Payment: "/payment", // 주문결제
  PaymentSuccess: "/payment-success", // 주문완료
  PaymentFailure: "/payment-failure", // 주문실패
  ProductDetail: "/product-detail", // 책 상세
  Field: "/field-page", // 분야별 도서 메인
  FieldDetail: "/field-page-detail", // 분야별 도서 상세
  Partner: "/partner", // 조합원 도서 메인
  NewBook: "/new-book", // 새로 나온 책 메인
  RecommendedBook: "/recommended-book", // 추천 도서 메인
  RecommendedBookDetail: "/recommended-book-detail", // 추천 도서 상세
  EventMainPage: "/event", // 기획/이벤트 메인
  EventPlanPage: "/event/plan", // 기획/이벤트 상세
  ModooMall: "/modoo-mall", // 모두같이 메인
  Support: "/support", // 고객센터 메인
  Notice: "/support/notice", // 고객센터 공지사항
  NoticeDetail: "/support/notice-detail", // 고객센터 공지사항 상세
  Faq: "/support/faq", // 고객센터 자주묻는질문
  OneToOne: "/support/one-to-one", // 고객센터 1:1문의
  OneToOneInquiry: "/support/one-to-one-inquiry", // 고객센터 1:1문의 작성
  MyPage: "/my-page", // 마이페이지 메인
  MileageHistory: "/my-page/mileageHistory", // 마이페이지 적립금 내역
  CouponList: "/my-page/coupon", // 마이페이지 쿠폰함
  EditProfile: "/my-page/editProfile", // 마이페이지 회원정보수정
  Widthdraw: "/my-page/widthdraw", // 마이페이지 회원탈퇴
  WidthdrawSuccess: "/widthdraw-success", // 회원탈퇴 성공
  OrderHistory: "/my-page/orderHistory", // 마이페이지 주문내역
  OrderHistoryDetail: "/my-page/orderHistoryDetail", // 마이페이지 주문내역상세
  ReturnInquiry: "/my-page/returnInquiry", // 마이페이지 반품신청
  ExchangeInquiry: "/my-page/exchangeInquiry", // 마이페이지 교환신청
  ShippingAddress: "/my-page/shippingAddress", // 마이페이지 배송지 관리
  LikeList: "/my-page/likeList", // 마이페이지 나의 즐겨찾기
  Review: "/my-page/review", // 마이페이지 내 리뷰
  CartView: "/cart-view", // 마이페이지 장바구니
  Mall: "/mall", // 입점몰 메인
  MallCommunity: "/mall/community", // 입점몰 커뮤니티 (스토리 / 채널 / 새소식)
  MallCommunityDetail: "/mall/community-detail", // 입점몰 커뮤니티 상세 (스토리 / 채널 / 새소식)
  // ConfirmPassword: "/confirm", // 입점몰 커뮤니티 (스토리 / 채널 / 새소식)
  UnivBook: "/mall/univ-book", // 구내서점
  UnivGoods: "/mall/univ-goods", // 굿즈페이지
  AboutModoo: "/about-modoo", // 책광장모두 사이트소개
  NaverSignInCallback: "/sign-in/naver",
  PostAuth: "/postAuth",
  SignUpSuccess: "/sign-up-success",
  PostSNSSignIn: "/postSnsSignIn",
  MallAlias: "/:mallAlias",
  Error: "/error",
} as const;

export default URLs;
