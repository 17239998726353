import { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export type PageType =
  | "MALL_ONLY"
  | "MODOO_ONLY"
  | "SHARE_HEADER"
  | "ONLY_PARAM";

export const usePageClassification = () => {
  const [
    searchParams,
    // setSearchParams
  ] = useSearchParams();
  const location = useLocation();
  const { pathname } = location;
  const currentShopSeq = useMemo(
    () => searchParams.get("shopSeq") ?? "",
    [searchParams],
  );

  const currentPageType: PageType = useMemo(
    () => getPageType(pathname),
    [pathname],
  );

  return { currentPageType, currentShopSeq, getPageType };
};

const getPageType = (pathname: string): PageType => {
  // console.log("=====getPageType=====");
  // console.log("pathname: ", pathname);
  if (pathname == "/" || pathname == "/about-modoo")
    // 모두 메인페이지는 MODOO_ONLY
    return "MODOO_ONLY";

  if (pathname.includes("/mall"))
    // 입점몰 메인 / 커뮤니티 - MALL_ONLY
    return "MALL_ONLY";

  if (
    // 8 ~ 12번 (입점몰 유입되었다는 데이터만 유지) - ONLY_PARAM
    pathname.includes("/support") || // 8번
    pathname.includes("/sign") || // 9-10번 - 로그인, 회원가입
    pathname.includes("password") || // 9-10번 - 비밀번호 재설정
    pathname.includes("/user") || // 9-10번 - 아이디/비밀번호 찾기, 아이디 확인
    pathname.includes("/my-page") || // 11번
    pathname.includes("/widthdraw") || // 11번 - 회원탈퇴 성공
    pathname.includes("/confirm") || // 11번 - 마이페이지 회원정보 수정 전 비밀번호 확인
    pathname.includes("/payment") || // 12번
    pathname.includes("/cart") // 12번
  )
    return "ONLY_PARAM";

  if (
    // 2 ~ 6번 (헤더 변경)
    pathname.includes("/field") || // 분야별 도서
    pathname.includes("/partner") || // 조합원 도서
    pathname.includes("/new-book") || // 새로 나온 책
    pathname.includes("/recommended-book") || // 추천도서
    pathname.includes("/event") || // 기획/이벤트 + 상세
    pathname.includes("/modoo-mall") || // 모두같이 메인
    pathname.includes("/product-detail") || // 책 상세
    pathname.includes("/search-result") // 검색 결과
  )
    // 2 ~ 6번 (특정 입점몰의 헤더를 쓰거나, 모두의 헤더를 사용함) - SHARE_HEADER
    return "SHARE_HEADER";

  // 여기까지 왔으면 걸러내지 못한 페이지가 있는 것으로, 추가 처리가 필요한 상황.
  console.error("getPageType error: 분기처리되지 않은 페이지가 존재합니다.");
  console.error(`pathname: ${pathname}`);
  return "MODOO_ONLY";
};
