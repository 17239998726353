import { useCallback } from "react";
import { NavigateOptions, To, useNavigate } from "react-router-dom";
import { usePageClassification } from "./usePageClassification";

export const useMyNavigation = () => {
  const { currentShopSeq, getPageType } = usePageClassification();

  const _navigate = useNavigate();

  const navigate = useCallback(
    (to: To, options?: NavigateOptions) => {
      // console.log(to);
      // if (
      //   !(
      //     to == "/" ||
      //     to == "/about-modoo" ||
      //     (`${to}`.includes("/mall") && !`${to}`.includes("/community")) ||
      //     `${to}`.includes("/cart-view") ||
      //     `${to}`.includes("/payment") ||
      //     `${to}`.includes("/product-detail")
      //   )
      // ) {
      //   alert("준비중입니다.");
      //   return;
      // }
      const pageType = getPageType(`${to}`);
      if (pageType == "MODOO_ONLY") {
        _navigate(to, options);
        return;
      }

      // 분기조건 - currentShopSeq 유무 / queryString 유무 / queryString 내의 shopSeq 유무

      if (!currentShopSeq) {
        // currentShopSeq가 없는 경우
        // 주어진 shopSeq를 태운다.
        _navigate(to, options);
        return;
      }

      // currentShopSeq가 무조건 있는 경우
      // 분기조건 - queryString 유무 / queryString 내의 shopSeq 유무

      const [_, queryString] = `${to}`.split("?");
      // queryString이 없는 경우
      // queryString을 만들어서 currentShopSeq를 태운다.
      if (!queryString) {
        _navigate(`${to}?shopSeq=${currentShopSeq}`, options);
        return;
      }

      // queryString이 무조건 있는 경우
      // shopSeq를 가지고 있으면, 새 shopSeq가 의도된 내용이므로 그대로 보낸다.
      const shopSeqIndex = `${to}`.indexOf("shopSeq=");

      if (shopSeqIndex >= 0) {
        //shopSeq를 가지고 있는 경우
        _navigate(to, options);
        return;
      }

      // shopSeq를 안 가지고 있으면, currentShopSeq를 태운다.
      _navigate(`${to}&shopSeq=${currentShopSeq}`, options);
      return;
    },
    [_navigate, getPageType, currentShopSeq],
  );
  return { navigate };
};
