import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: `"Noto Sans KR", sans-serif`,
  },
  palette: {
    primary: {
      main: "#1e76af",
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          // "&$error": {
          //   color: "#db3131",
          // },
        },
      },
    },
  },
});
