import { axiosInstance } from "config/axiosConfig";

export const getShopList = async () => {
  const response = await axiosInstance.get("/shop/getListShop", {
    params: {},
  });
  return response.data;
};

export const getShopListBySearchParams =
  ({
    page = "1",
    rowsPerPage = "6",
    keyword = "",
    shopTypCd = "O",
  }: {
    page: string;
    rowsPerPage: string;
    keyword: string;
    shopTypCd: string;
  }) =>
  async () => {
    const response = await axiosInstance.get("/shop/getListShop", {
      params: { page, rowsPerPage, keyword, shopTypCd },
    });
    return response.data;
  };

export const getShopDetail = (shopSeq: string) => async () => {
  const response = await axiosInstance.get("/shop/getOneShop", {
    params: { shopSeq },
  });
  return response.data;
};

export const getTemplateData = async () => {
  const response = await axiosInstance.get("/shop/getOneModoo");
  return response.data;
};

export const getBkmrShopDetail = (shopSeq: string) => async () => {
  const response = await axiosInstance.get("/shop/getListBkmrShop", {
    params: {
      shopSeq,
    },
  });
  return response.data;
};

export const getShopFromAlias = async (alias: string) => {
  const response = await axiosInstance.get("/shop/getDomainToSeq", {
    params: {
      domainAlias: alias,
    },
  });
  return response?.data?.data?.shopSeq;
};
