import { useCallback, useEffect } from "react";
import { setRecentBookList, useRecentBookList } from "stores/recentBookStore";
import { Book } from "types/book";
const MD_RECENT = "MD_RECENT";

export const useRecentBook = () => {
  // 로컬스토리지 CRUD 구현, 화면 업데이트를 위한 전역상태 사용
  const recentBookList = useRecentBookList();
  /*
  최근 본 도서 C (+ U)
  이미 있는 도서일 경우 추가하지 않음
  5권을 초과할 경우 가장 먼저 들어왔던 책을 삭제 (최종적으로 MAX 5권)
  recentBookList에 insert, 로컬스토리지 업데이트
  */
  const handleRecentBookInsert = useCallback(
    (newBook: Book) => {
      let newBookList = [...recentBookList];

      if (newBookList.find((book) => book.bookCd == newBook.bookCd)) {
        newBookList = newBookList.filter(
          (book) => book.bookCd != newBook.bookCd,
        );
      }
      newBookList.push(newBook);
      if (newBookList.length > 5) {
        newBookList = newBookList.slice(1, 6);
      }
      localStorage.setItem(MD_RECENT, JSON.stringify(newBookList));
      setRecentBookList([...newBookList]);
    },
    [recentBookList],
  );
  /* 
  최근 본 도서 R
  로컬스토리지에서 긁어와서 recentBookList에 insert
  */
  useEffect(() => {
    let recentBookListInLocalStorage = localStorage.getItem(MD_RECENT);
    // 로컬스토리지에 기록이 없거나, null 책이 들어간 경우...
    if (
      !recentBookListInLocalStorage ||
      recentBookListInLocalStorage == "[null]"
    ) {
      localStorage.setItem(MD_RECENT, JSON.stringify([]));
      recentBookListInLocalStorage = "[]";
    }
    const recentBookList = JSON.parse(recentBookListInLocalStorage) as Book[];
    setRecentBookList([...recentBookList]);
  }, []);

  /* 
  최근 본 도서 D 
  선택된 책을 recentBookList에서 제거, 로컬스토리지 업데이트
  */
  const handleRecentBookDelete = useCallback(
    (deletedBook: Book) => () => {
      const newBookList = [
        ...recentBookList.filter((book) => book.bookCd != deletedBook.bookCd),
      ];
      localStorage.setItem(MD_RECENT, JSON.stringify(newBookList));
      setRecentBookList([...newBookList]);
    },
    [recentBookList],
  );

  return {
    recentBookList,
    recentBookCount: recentBookList.length,
    handleRecentBookInsert,
    handleRecentBookDelete,
  };
};
